<template>
 <div class="">
   <div class="menu-box"
        :style="menuBoxStyle"
        @mouseenter="handleMouseEnter"
        @mouseleave="handleMouseLeave"
   >
     <div class="container">
       <div class="flex align-center">
         <a class="menu-logo" href="../">
           <img :src="logo" alt="">
         </a>
         <ul class="flex align-center menu-one">
           <li v-for="(item, index) in menuList"
               :class="activeMenu == item.value ? 'active' : ''"
               :key="index"
               @mouseenter="mouseenterMenu(item)"
               @click="changeMenu(item)">
             <!--<p>{{}}</p>-->
             <span >{{item.name}}</span>
           </li>
         </ul>
       </div>
     </div>

     <div class="menu-children-box">
       <div class="container">
         <div v-if="childrenMenuList.length > 0" class="flex align-center menu-children" :style="{paddingLeft: childrenMenuPadding + 'rem'}">
           <div  v-for="(item_, index_) in childrenMenuList" :key="index_"
                 :class="activeMenuChildren == item_.routerName ? 'active' : ''"
                 @click="changeMenuChildren(item_)">
             {{item_.name}}
           </div>
         </div>
       </div>
     </div>

   </div>
 </div>
</template>

<script>
import Public from "@/assets/js/public";
export default {
  name: 'Menu',
  components: {
  },
  props: {
    type: {
      type: String,
      default: 'other' // home 默认穿透 other 默认白的背景
    }
  },
  mixins: [],
  data () {
    return {
      logo: this.type != 'home' ? require('@/assets/images/logo-active.png') : require('@/assets/images/36.fw.png'),
      activeMenu: 1,
      activeMenuChildren: null,
      activeMenuChildrenActive: 1,
      menuList: [
        {name: '首页', routerName: 'home', value: 1},
        {
          name: '解决方案', routerName: 'plan4', value: 2, children: Public.plan()
        },
        {
          name: '产品服务', routerName: 'product4', value: 3, children: Public.product()
        },
        {name: '生态合作', routerName: 'cooperation', value: 4},
        {name: '产教融合', routerName: 'fuse', value: 5},
        {name: '新闻资讯', routerName: 'news', value: 6},
        {name: '关于安冉云', routerName: 'about', value: 7},
      ],
      // 子菜单
      childrenMenuList: [],
      scrollHeight: 0
    };
  },
  computed: {
    // 子菜单距离
    childrenMenuPadding () {
      let logoWidth = 1.58;
      let logoPaddding = 1.50;
      let menuItemWidth = 1.32;
      return logoWidth + logoPaddding + (menuItemWidth * (this.activeMenuChildrenActive - 1))
    },
    menuBoxStyle () {
      let obj = {
        background: this.scrollHeight > 100 || this.type != 'home' ? '#fff' : '',
        color: this.scrollHeight > 100 || this.type != 'home' ? 'inherit' : '',
        boxShadow: this.scrollHeight > 667 ? '1px 1px 20px #f8f8f8' : ''
      }
      return obj
    },
  },
  watch: {
    '$route': { // $route可以用引号，也可以不用引号  监听的对象
      handler(to){
        let name = to.name;
        this.activeMenu = name == 'home' ? 1 : name.includes('plan') ? 2 :
          name.includes('product') ? 3 : name.includes('cooperation') ? 4 :
            name.includes('fuse') ? 5 : name.includes('news') ? 6 :
              name.includes('about') ? 7 : 1
        this.activeMenuChildren = name;
        window.scrollTo(0, 0); // 每次路由切换后滚动到顶部
      },
      deep: true, // 深度观察监听 设置为 true
      immediate: true, // 第一次初始化渲染就可以监听到
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll);
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.handleScroll);
  },
  created () {

  },
  methods: {
    handleScroll() {
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      this.scrollHeight = scrollTop;
      this.logo = scrollTop > 100 ||this.type != 'home' ? require('@/assets/images/logo-active.png') : require('@/assets/images/36.fw.png');
      if (scrollTop > 100) {
        this.childrenMenuList = []
      }
      // 这里可以根据scrollHeight进行相应的操作
    },
    handleMouseEnter() {
      this.logo = require('@/assets/images/logo-active.png')
    },
    handleMouseLeave() {
      this.logo = this.scrollHeight > 100 || this.type != 'home' ? require('@/assets/images/logo-active.png') : require('@/assets/images/36.fw.png');
      this.childrenMenuList = [];
    },
    /* hoverMenu */
    mouseenterMenu (item) {
      this.activeMenuChildrenActive = item.value;
      this.childrenMenuList = item.children || [];
    },
    /* 切换menu */
    changeMenu (item) {
      this.activeMenu = item.value;
      this.activeMenuChildrenActive = item.value;
      this.childrenMenuList = item.children || [];
      if (item.routerName) {
        this.$router.push({name: item.routerName})
      }
    },
    /* 切换menu子菜单 */
    changeMenuChildren (item) {
      this.activeMenuChildren = item.routerName;
      if (item.routerName) {
        this.$router.push({name: item.routerName, query: {title: item.name}})
      }
    }
  }
};
</script>
<style scoped  lang="scss">
.menu-box{
  position: fixed;top:0;left: 0;z-index: 100;width: 100%;padding-top:.10rem;color: #fff;
  .menu-logo{
    margin-right: 1.50rem;cursor: pointer;
    img{width: 1.58rem;}
  }
  ul.menu-one{
    height: .72rem;line-height: .72rem;font-size:.16rem;
    li{
      width: 1.32rem;text-align: center;height: 100%;cursor: pointer;position: relative;
      span{
        padding-bottom:.12rem;
      }

    }
    li:after{
      content: "";
      display: block;
      width: .3rem; /* 或者指定宽度 */
      height: .04rem; /* 线的粗细 */
      background: transparent; /* 线的颜色 */
      border-radius: .04rem;
      position: absolute; /* 或者其他定位方式 */
      bottom: .10rem; /* 线与元素顶部对齐 */
      left: 0;
      right: 0;
      margin: 0 auto;
    }
    li.active:after{
      background: #4285F4;
    }
  }
  .menu-children{
    height: .72rem;line-height: .72rem;font-size:.16rem;width: 100%;
    div{width: 1.32rem;text-align: center;cursor: pointer}
    div.active{color: #4285F4;font-weight: bold}
  }
}
.menu-box:hover{background: #fff;color: inherit}
.menu-box:hover .menu-children-box{background: #f7f7f7;}
</style>
<style>
</style>
