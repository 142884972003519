/* 公共数据 */
export default {
  /* 数字滚动 */
  formatterNum (num) {
    let s = (String(num)).includes('.') ? 1 : 0
    return num.toFixed(s)
  },
  // 解决方案列表
  plan() {
    return [
      {name: '园区网络运营', routerName: 'plan4', value: 2.4},
      {name: '数字孪生校园', routerName: 'plan3', value: 2.3},
      {name: '5G专网', routerName: 'plan1', value: 2.1},
      {name: '网络安全服务', routerName: 'plan2', value: 2.2},
      {name: '智能运维', routerName: 'plan5', value: 2.5},
    ]
  },
  // 产品服务列表
  product() {
    return [
      {name: '智慧招生宣传', routerName: 'product4', value: 3.4},
      {name: '智慧迎新', routerName: 'product3', value: 3.3},
      {name: '云书院', routerName: 'product2', value: 3.2},
      {name: '离校系统', routerName: 'product1', value: 3.1},
      {name: '校友系统', routerName: 'product5', value: 3.5},
    ]
  },
  // 解决方案 (5g专网)
  plan1 () {
    let obj = {
      routerName: 'plan1',
      txt: '5G专网是安冉云针对校园场景打造的随行专网系列产品之一。依托5G精品网和智能分流能力，实现用户不换卡不换号便可随时随地访问校园内网，打造公/专网无感切换的优质体验。有效解决传统校园专网体验差、风险大、管理难等痛点。',
      txt1: '同时基于5G校园随行专网的特性，为学校建设资源管理门户，帮助学校针对教师和学生整理和分发应用和资源，提高学校资源利用率，方便教师和学生在校外能快速解决办公和学习问题。',
      img: require('@/assets/images/23.fw.png')
    };
    return obj;
  },
  // 网络完全
  plan2 () {
    let obj = {
      routerName: 'plan2',
      txt: '为用户提供的安全风险管理和安全运营管理的一套服务；整合了安全专家、AI分析平台、贴身运营服务人员等多个层面的工具和力量，为用户提供持续、主动、闭环的安全运营服务。结合安冉云的MSP服务模式，帮助用户解决“安全效果难以保障”、“安全服务不持续”等传统安全服务模式存在的顽疾，让用户不必重复投入人力，即可形成安全运营的能力',
      txt1: '',
      img: require('@/assets/images/24.fw.png')
    };
    return obj;
  },
  // 信息化服务
  plan3 () {
    let obj = {
      routerName: 'plan3',
      txt: '以数字孪生为基础，实现对现实世界的全域感知、镜像再现，在数字孪生世界里构建智慧校园各类管理应用，达到对现实校园的全面监测、有序管理和运营服务，通过数据整合、联动可视、打破系统墙、数据墙、部门墙，真正的统一平台顶层设计，真正的做到可视、可用、可管。',
      txt1: '',
      imgL: require('@/assets/images/29.fw.png')
    };
    return obj;
  },
  // 园区网络运营
  plan4 () {
    let obj = {
      routerName: 'plan4',
      title: '智慧招生',
      txt: '为提高网络资产的集约利用水平，投入科研力量攻关且创造性的实现了超大型园区环境下的多网融合技术落地，并为园区提供“共建、共享、更集约、更经济”的精细化运维服务，同时集合边缘计算技术理论进行深度优化，实现高峰期流量的卸载分流，从而为用户带来了更优质的用网体验，更极大的节省了网络运营费用支出，形成了特有的“网好、不贵、安全、省心”的网络管家运营服务模式。',
      txt1: '',
      img: require('@/assets/images/22.fw.png')
    };
    return obj;
  },
  // 智能运维平台
  plan5() {
    let obj = {
      routerName: 'plan5',
      title: '智能运维平台',
      txt: '智能运营系统平台是一个围绕着网络运维服务工作流程构建的运维管理工具，旨在规范网络运维服务工作流程、提升网络运维服务工作效率。搭配运维服务体系，可以解决高校校园网运维全设备统一管理难、故障处理难闭环、用户用网体验差、运维服务不可视等问题。',
      txt1: '通过大屏观综合态势，了解设备及关键链路运行状态。通过中屏管调度，查询分析告警事件，结合四级运维服务体系，处置或派发工单，分发调度到驻场服务团队。通过小屏做执行，驻场服务团队接单、处置、完结，完成网络故障处理的闭环。',
      img: require('@/assets/images/09616.jpg'),
      img1: require('@/assets/images/home/05.jpg'),
    }
    return obj
  },
  // 离校系统
  product1() {
    let obj = {
      routerName: 'product1',
      title: '离校系统',
      txt: '离校系统面向学校各学院（部、中心、所）、相关部门以及全体毕业本科生和研究生提供离校综合管理服务。离校系统的使用将方便毕业生进行离校手续的办理，在一定程度上优化离校流程，加强参加离校的各相关部门间的信息流通和工作配合，提高学校离校工作的效率。',
      txt1: '让毕业生少跑腿、教师少盖章，支持按照学校需求，个性化配置办理流程，待办清单可对接学校系统，做到在线审核，学生可以按清单自助办理。',
      img: require('@/assets/images/03.fw.png'),
      img1: require('@/assets/images/home/04.jpg'),
    }
    return obj
  },
  // 云书院
  product2() {
    let obj = {
      routerName: 'product2',
      title: '云书院系统',
      txt: '云书院是安冉云以书院制改革为背景下，研发的“一站式”学生社区综合管理服务平台，平台基于微服务架构，以“管理+服务”为理念，为高校提供全链式学生管理服务，云书院主要面向育人导师、辅导员提供开展各类书院活动、日常学生管理、学生生活行为大数据分析等功能。',
      txt1: '云书院支持本地部署，数据掌握在学校手里数据信息更安全。支持微信服务号、钉钉、企业微信、学校官方APP的接入',
      img: require('@/assets/images/02.fw.png'),
       img1: require('@/assets/images/home/03.jpg'),
    }
    return obj
  },
  // 智慧迎新
  product3() {
    let obj = {
      routerName: 'product3',
      title: '智慧迎新系统',
      txt: '智慧迎新，是以本地部署形式，为高校提供一套新生入校助手服务的工具化软件。帮助学生做到入学前信息填报、入学预约、到校自助办理等流程辅助，新生通过迎新系统，提前填报信息，学校按每日新生到校数量，提前协调资源和相关人员，有序安排，有序分配，入学高效畅通。',
      txt1: '结合新生的入校点位信息，院系信息，用智能化设备、清单化引导，做到新生自助核验、报到，线上交费，有序领取物资后即可入住。',
      img: require('@/assets/images/09.fw.png'),
       img1: require('@/assets/images/home/02.jpg'),
    }
    return obj
  },
  // 智慧招生
   product4() {
     let obj = {
       routerName: 'product4',
       title: '智慧招生宣传',
       txt: '智慧招生宣传为公立、私立高校提供一个多渠道潜在生源收集工具。利用学校的公众号、抖音、微博等渠道，统计和收集页面访问人员的信息和数据，用于精准营销。同时支持以裂变传播的方式，为高校提供学生推荐学生的传播链条，让学校与移动互联网更加贴合，展示更优化，数据更精准。',
       txt1: '',
       img: require('@/assets/images/10.fw.png'),
        img1: require('@/assets/images/home/01.jpg'),
     }
     return obj
  },
  // 校友系统
  product5() {
     let obj = {
       routerName: 'product5',
       title: '校友系统',
       txt: '校友系统是一种以校友为主要用户群体的社交网络平台，其主要目的是促进校友之间的联系和交流，并提供与母校相关的资源和服务。',
       txt1: '除了提供基本的社交功能外，校友系统还可以为校友提供更多的服务，例如职业发展、资源共享、校友活动、志愿服务等。校友系统将不仅仅是一个社交平台，还将成为校友们生活和工作中不可或缺的一部分。',
       img: require('@/assets/images/img13.jpg'),
        img1: require('@/assets/images/img12.jpg'),
     }
     return obj
  },
}
