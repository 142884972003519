<template>
 <div class="">
   <el-dialog
       :show-close="false"
       :visible="visible"
       :lock-scroll="false"
       :destroy-on-close="true"
       :close-on-click-modal="false"
       custom-class="ele-dialog-form"
       @update:visible="updateVisible">
     <div class="dialog-box">
       <div class="dialog-box-c">
          <div class="flex align-center dialog-box-c-t">
            <img src="@/assets/images/img8.png" alt="">
            <div>
              <h2>{{title}}</h2>
              <p v-if="subtitle">为高校提供高品质的网络运营及信息化服务</p>
            </div>
          </div>
         <div class="dialog-box-c-b">
           <img :src="img" alt="">
         </div>
         <img class="dialog-box-close-btn" @click="close" src="@/assets/images/img10.png" alt="">
       </div>

     </div>
   </el-dialog>
 </div>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    // 弹窗是否打开
    visible: Boolean,
    title: {
      type: String,
      default: '扫码获取方案'
    },
    subtitle: {
      type: Boolean,
      default: true
    },
    img: {
      type: String,
      default: require('@/assets/images/img9.jpg')
    }
  },
  components: {
  },
  mixins: [],
  data () {
    return {

    }
  },
  watch: {
  },
  mounted () {},
  created () {

  },
  methods: {
    close () {
      this.updateVisible(false)
    },
    /* 更新visible */
    updateVisible(value) {
      this.$emit("update:visible", value);
    }
  }
};
</script>
<style scoped  lang="scss">
.dialog-box{

}
.dialog-box-c{
  margin: 0 auto;
  width: 4.21rem;height: 5.15rem;padding: .14rem .20rem .30rem;
  background: url("~@/assets/images/bg10.png") no-repeat;
  background-size: cover;
  position: relative;
  .dialog-box-close-btn{
    position: absolute;
    right: -.7rem;
    top:-.2rem;
    cursor: pointer;
  }
  .dialog-box-c-t{
    img{width: .61rem;height: .61rem;min-width: .61rem;margin-right: .1rem;}
    h2{font-size:.18rem;font-weight: bold;margin-bottom:.1rem;padding-top:.15rem;}
    p{font-size: .12rem;color: #a3a8bb;}
  }
  .dialog-box-c-b{
    margin-top:.30rem;
    padding:.35rem .30rem 0;
    img{width: 100%}
  }
}

</style>
<style>
.el-dialog{
  background: none;
  box-shadow: none;

}
</style>
