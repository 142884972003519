<template>
 <div class="footer-box">
   <div class="footer-box-top flex align-center justify-center">
     <h1>选择我们，了解更多园区网络运营服务方案</h1>
     <button @click="showDialog">获取方案 <img src="@/assets/images/48.fw.png" alt=""></button>
   </div>
   <div class="container ">
     <div class="footer-box-center flex">
       <div class="footer-box-center-l">
         <h2>咨询热线</h2>
         <div><img src="@/assets/images/51.fw.png" alt=""><span class="cph-font">0371-61235359</span></div>
         <p>地址：郑州市金水区新科技市场5号楼5楼</p>
       </div>
       <div class="footer-box-center-c">
         <ul class="flex">
           <li v-for="(item, index) in centerList" :key="index">
             <h2>{{item.name}}</h2>
             <p v-for="(item_, index_) in item.list" @click="jumpUrl(item_)" :key="index_">{{item_.name}}</p>
           </li>
         </ul>
       </div>
       <div class="footer-box-center-r flex">
         <div>
           <h2>大学掌公众号</h2>
           <img src="@/assets/images/53.fw.png" alt="">
         </div>
         <div>
           <h2>大学掌小程序</h2>
           <img src="@/assets/images/mini-a.png" alt="">
         </div>
       </div>
     </div>
   </div>
   <div class="footer-box-bottom">
     Copyright © 2021 河南安冉云网络科技有限公司 All Rights Reserved &nbsp;豫ICP备15004493号-1
   </div>
   <!-- 获取方案 -->
   <dialog-template :visible.sync="dialogShow"/>
 </div>
</template>

<script>
import DialogTemplate from "@/components/Dialog/index.vue";
import Public from "@/assets/js/public";
export default {
  name: 'Footer',
  components: {
    DialogTemplate
  },
  mixins: [],
  data () {
    return {
      centerList: [
        {name: '走进安冉云', routerName: '', list: [
            {name: '公司介绍', routerName: 'about'},
            {name: '发展历程', routerName: 'about'},
            {name: '荣誉资质', routerName: 'about'},
            {name: '企业文化', routerName: 'about'},
          ]
        },
        {name: '解决方案', routerName: '', list: Public.plan()},
        {name: '产品服务', routerName: '', list: Public.product()},
      ],
      dialogShow: false
    }
  },
  watch: {
  },
  mounted () {},
  created () {

  },
  methods: {
    showDialog() {
      this.dialogShow = true
    },
    /* 跳转页面 */
    jumpUrl (item) {
      if(item.routerName) {
        this.$router.push({name: item.routerName})
      }
    },
  }
};
</script>
<style scoped  lang="scss">
.footer-box{
 min-height: 5rem;background: #21212e;width: 100%;position: relative;margin-top:1.45rem;
  .footer-box-top{
    width: 12.65rem;
    max-width: 100%;
    height: 1.3rem;
    background: url("~@/assets/images/33.fw.png") no-repeat;
    background-size: cover;
    position: absolute;top:-.57rem;margin: 0 auto;
    left: 0;right: 0;
    button{
      width: 2.04rem;height: .60rem;border: 1px solid #fff;color: #fff;border-radius: .60rem;background: none;font-size:.24rem;margin-left:.47rem;
      img{height: .24rem;margin-left:.22rem}
    }
  }
  h1{color: #fff;font-size:.36rem;}
  .footer-box-center{
    margin-top:1.2rem;color: #6b6b75;
    h2{color: #fff;font-size: .20rem;margin-bottom:.30rem;}
    .footer-box-center-l{margin-right: .92rem;min-width: 2.8rem;max-width: 2.80rem;padding-left: .2rem}
    .footer-box-center-l div{color: #007fff;font-size:.24rem;margin-bottom:.1rem;img{margin-right: .2rem;}}
    .footer-box-center-c{
      flex: 1;
      li{margin-right:.8rem;
        p{font-size:.16rem;cursor: pointer;}
      }
      li:last-child{margin-right:0}
    }
    .footer-box-center-r{
      min-width: 3.35rem;max-width: 3.35rem;
      div{
        margin-right: .62rem;
        img{width: 1.30rem;height: 1.30rem;background: #fff;border-radius: .1rem;padding:.05rem}
      }
    }
    p{font-size:.14rem;line-height: .44rem;}
  }
  .footer-box-bottom{
    text-align: center;color: #5d5d67;border-top:1px solid #353541;padding-top:.30rem;margin-top:.30rem;
  }
}
</style>
<style>
</style>
